<template>
  <KTCard :page="page">
    <template #toolbar>
      <KTAddButton :to="addRoute" v-if="!finalizado" />
    </template>
    <template #body>
      <KTTable
        :fields="custo_producao_columns"
        :items="custo_producao.data"
        :primaryKey="custo_producao.primaryKey"
        :sortBy="custo_producao.sortBy"
      >
        <template #cell(_actions)="{ item }">
          <KTViewMiniButton
            :to="getEditRoute(item.idpip_custo_producao)"
            v-if="finalizado"
          />
          <KTEditMiniButton
            class="mr-3"
            :to="getEditRoute(item.idpip_custo_producao)"
            v-else
          />
          <KTRemoveMiniButton
            @click="ApiRemoveItem(item.idpip_custo_producao)"
            v-if="!finalizado"
          />
        </template>
      </KTTable>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipCustoProducaoList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-donate",
        title: "Custos de Produção",
        description: `Os custos de produção são aqueles relacionados ao processo
          produtivo, podendo ser divididos em custos indiretos, material direto
          e mão de obra direta, ou seja, todos os itens que são obrigatórios no
          processo produtivo. Clique no botão Adicionar para cadastrar um custo
          de produção. Para remover ou editar algum item use os respectivos botões
          localizados abaixo de AÇÕES.`
      },
      custo_producao: {
        data: [],
        primaryKey: "idpip_custo_producao",
        sortBy: "discriminacao"
      },
      anos: 0,
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/custo_producao`;
    },
    manual() {
      return {
        session: "custo_producao",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    addRoute() {
      return {
        name: "pip_custo_producao_new",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    custo_producao_columns() {
      let columns = [
        {
          key: "_actions",
          label: "Ações",
          sortable: false,
          tdClass: "text-center"
        },
        {
          key: "discriminacao",
          label: "Discriminação do Insumo",
          sortable: true
        },
        {
          key: "nome_unidade_medida",
          label: "Unidade de Medida",
          sortable: true,
          tdClass: "text-center"
        },
        {
          key: "descricao_produto",
          label: "Descrição do Produto",
          sortable: true
        },
        {
          key: "simbolo_moeda",
          label: "Moeda",
          sortable: true,
          tdClass: "text-center"
        }
      ];

      for (let ano = 1; ano <= this.anos; ano++) {
        columns.push({
          key: "quantidade_total_ano_" + ano,
          label: "Quantidade Ano " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "number"
        });
        columns.push({
          key: "valor_total_ano_" + ano,
          label: "Valor Ano " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "currency"
        });
      }

      return columns;
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.custo_producao.data = res[R_GETLIST].custo_producao;
        this.anos = parseInt(res[R_GETLIST].anos);
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;
        this.finalizado = res[R_GETLIST].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    getEditRoute(id) {
      return {
        name: "pip_custo_producao_edit",
        params: {
          idplano_investimento: this.idplano_investimento,
          idpip_custo_producao: id
        }
      };
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
